.Upload_Your_Plans{
    color: white;
   background-color:#20B621 ;
   border: none; font-weight: 700;
   padding: 10px;
   border-radius: 10px;
}
.Samplesbtn{
    color: white;
   background-color:#20B621 ;
   border: none; font-weight: 700;
   padding: 10px 40px;
   border-radius: 10px;
   margin-left: 10px;
}
.bannerContainer{
    background-image: url("../../Images//bannerBG.png");
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    margin-bottom: 30px;
}
.BannerText{
    color: white;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    padding: 1px 30px;
   
}
@media screen and (max-width:500px) {
    .BannerText{
        color: white;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        padding: 1px 0px;
       
    }
}