.PaymentMaythedContainer {

  
  margin-top: 60px;
  position: relative;
  border-radius: 40px;
  border: 2px dashed black;
  padding: 40px;
}
.Payment_Method {
  position: absolute;
  top: -23px;
  background-color: #cff0c5;
  left: 30px;
}
.BankIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.bankReffrenceLink{
  
}
.BankIconContainer  img{
  
  transition: .5s;
}
.BankIconContainer  img:hover{
  box-shadow: 0px 0px 6px 1px black;
  height: 175px;
  width:  179px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: .5s;
}
