.PlanHubheader{
 
  width: 100%;
  z-index: 1;
 
  position: fixed !important;
  top: 0px;
}
.nav-link {
  text-align: center !important;
}
.GetEstimate {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  background: #20b621;
  border: none; 
  color: white;
  padding: 02px 12px;
}
.NavLink{
  text-decoration: none;
  text-align: center;
  color: black;
  font-weight: 600;
  padding: 0px 10px;
}
.NavLink.active{
  background-color: #20b621;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.upperHeader{
  background-color: black;
  width: 100%;
  height: 70px;
  transition: 1s;
  display: flex;
  align-items: center;
  position: relative;
}
.socialMediaIconContainer{
  margin-left: 10px;
  position: absolute;
}
.socialMediaIconContainer a{
margin: 0px 5px;
}
.LogoPic{
  margin-left: 20px;
}

@keyframes blink {
  0% { opacity: 1; background-color: #20b621; transition: 1s;  }
  50% { opacity: 0;  background-color: #20b621; transition: 1s; }
  100% { opacity: 1;  background-color: #20b621; transition: 1s; }
}

.blinking-span {
  color: white;
  width: 0px;
  border-radius: 10px;
  margin-left: 20px;
  padding: 0px 10px;
  animation: blink 1.5s ease-in-out infinite; /* 1s duration, infinite loop */
}
@media screen and (max-width:1190px){
  .nav-link{
    padding: 4px !important
  }
}

@media screen and (max-width:1120px){
  .nav-link{
   font-size: 15px !important;
  }
}
@media screen and (max-width:990px) {
  .nav-link{
    font-size: 18px !important;
    padding: 6px !important
   }
  .GetEstimate {
    border-radius: 12px;
    background: #20b621;
    border: none;
    color: white;
    padding: 2px 12px;
}
}

@media screen and (max-width:700px) {
  .socialMediaIconContainer {
    margin-left: 0px;
    position: absolute;
}
  .displayNoneonSmallDevice{
    display: none;
  }

  .blinking-span {
    color: white;
    width: 0px;
    border-radius: 10px;
    margin-left: 55px;
    padding: 0px 10px;
    animation: blink 1.5s ease-in-out infinite;
}
}