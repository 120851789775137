
.PricingCardContainer {
  width: 100%;
  background-color: #2f2f2f;
  padding: 80px 0px;
}
.PricingCard1 {
  background-color: white;
  width: 280px;
  border-radius: 10px;
  transition: 0.5s;
  padding: 20px;
  min-height: 470px;
  margin-top: 30px;
}
.PricingCard2 {
  background-color: white;
  width: 280px;
  border-radius: 10px;
  padding: 20px;
  min-height: 470px;
  transition: 0.5s;
  margin-top: 30px;
}
.PricingCard3 {
  background-color: white;
  width: 280px;
  border-radius: 10px;
  padding: 20px;
  min-height: 470px;
  margin-top: 30px;
  transition: 0.5s;
}
.PricingCard1:hover {
  background-color: white;
  width: 280px;
  border-radius: 10px;
  padding: 30px;
  min-height: 520px;
  transition: 0.5s;
  margin-top: 30px;
}
.PricingCard2:hover {
  background-color: white;
  width: 280px;
  border-radius: 10px;
  padding: 30px;
  min-height: 520px;
  transition: 0.5s;
  margin-top: 30px;
}
.PricingCard3:hover {
  background-color: white;
  width: 280px;
  border-radius: 10px;
  padding: 30px;
  min-height: 520px;
  transition: 0.5s;
  margin-top: 30px;
}
.EstimationfeeCardContainer {
  border-radius: 10px;
  background: #2f2f2f;
  width: 210px;
  color: white;
  margin-top: 40px;
  padding: 15px;
  height: 620px;
}
.StartingAt {
  text-align: center;
  font-size: 12px;
  margin: 0px;
}
.paymentParagraph {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}
.EstimationfeeCardButton {
  color: black;
  font-weight: 600;
  width: 100%;
  display: inline-block;
  border: none;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  padding: 08px 30px;
  background-color: #FFF;
  margin: 07px 0px;
  transition: 0.4s;
}
.EstimationfeeCardButton:hover {
  background-color: grey;
  transition: 0.4s;
}
.EstimationfeePointsContainer {
  display: flex;
}
.pionttick {
  margin-right: 5px;
  display: inline-block;
}
.EstimationfeePoints {
  font-size: 10px;
  font-weight: 600;
}
.OurPackagesImg{
  width: 300px;
  height: 300px;
}
.OurPackagesImgContainer{
  display: flex;
  justify-content: center;
}